<template>
  <v-card v-if="collection" flat>
    <v-card-title>
      {{ collection.title }}
    </v-card-title>
    <v-card-subtitle>
      <v-icon small v-text="'mdi-firebase'" />db.collection('{{ collection.ref }}')
    </v-card-subtitle>
    <validation-observer ref="collectionForm" v-slot="{ valid }" slim>
      <v-card flat class="flex-grow-1">
        <v-card-text>
          <fire-string
            v-model="form.title"
            dense
            title="Collection Title"
            description="Enter a singular title for the items in this collection."
            required
            unique
          />
          <fire-string
            v-model="form.plural"
            dense
            title="Plural Title"
            :placeholder="form.title ? `${form.title}s` : ''"
            :description="form.title ? `Enter if you would like to use something other than '${form.title}s'` : ''"
          />
          <fire-card
            title="Fields"
            :loading="fieldsSaving"
          >
            <fire-fields-editor
              v-model="form.fields"
              @click:add="startAdd"
            />
          </fire-card>
          <fire-card
            title="List Preview"
            description="Choose which fields will be displayed when documents are displayed as lists."
            :loading="fieldsSaving"
          >
            <fire-select
              v-model="form.listPreviewTitle"
              title="Item Title"
              description="Leave blank to use item id"
              :items="['_createdAt', '_createdBy', '_updatedAt', ...form.fields.map(f => f.name)]"
              clearable
            />
            <fire-select
              v-model="form.listPreviewSubtitle"
              title="Item Subtitle"
              :items="['_createdAt', '_createdBy', '_updatedAt', ...form.fields.map(f => f.name)]"
              clearable
            />
            <fire-select
              v-model="form.listPreviewAvatar"
              title="Item Icon"
              description="Select an image or an icon field"
              :items="form.fields.filter(f => ['fire-image', 'fire-icon'].includes(f._type)).map(f => f.name)"
              clearable
            />
          </fire-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            text
            :disabled="!valid || saving"
            :loading="deleting"
            @click="deleteCollection"
          >
            Delete Collection
          </v-btn>
          <v-btn
            color="primary"
            type="submit"
            text
            :disabled="!valid || saving"
            :loading="saving"
            @click="save"
          >
            Save Collection
          </v-btn>
        </v-card-actions>
      </v-card>
    </validation-observer>
    <!-- RIGHT PREVIEW DRAWER -->
    <v-navigation-drawer
      v-model="sidebar"
      mobile-breakpoint="960"
      :width="sidebarWidth"
      class="sidebar"
      app
      right
      clipped
    >
      <div class="v-dialog--scrollable">
        <v-card v-if="addingNewField">
          <v-card-title>
            Add New Field
            <v-spacer />
            <v-btn icon @click="endAdd">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-subtitle class="pt-5 pb-0">
            <v-text-field
              v-model="filter"
              label="Filter"
              hide-details
              placeholder="Start typing to filter"
              outlined
              clearable
              dense
            />
          </v-card-subtitle>
          <v-card-text>
            <fire-fields-selector
              :filter="filter"
              :one-click="smAndDown"
              @select="addField"
            />
          </v-card-text>
        </v-card>
        <validation-observer
          v-else
          ref="previewForm"
          v-slot="{ validate }"
          class="w-full"
          tag="form"
          @submit.prevent="preview"
        >
          <v-card>
            <v-card-title>
              Studio Preview
            </v-card-title>
            <v-card-subtitle>
              Your document editor will look like this.
            </v-card-subtitle>
            <v-divider />
            <v-card-text>
              <transition-group type="transition">
                <component
                  :is="field._type"
                  v-for="field in form.fields"
                  :key="field.id"
                  v-model="mockValue[field.id]"
                  v-bind="field"
                  class="flip-list-move"
                />
              </transition-group>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-btn
                color="secondary"
                block
                outlined
                @click="validate"
              >
                Validate Form
              </v-btn>
            </v-card-actions>
          </v-card>
        </validation-observer>
      </div>
    </v-navigation-drawer>
    <!-- Field Editor -->
    <router-view :collection="collection" />
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { isEqual } from 'lodash-es'
import { db, Timestamp } from '@/plugins/firebase'
export default {
  name: 'Collection',
  data() {
    return {
      addingNewField: false,
      sidebar: null,
      form: { fields: [] },
      filter: '',
      fieldsSaving: false,
      saving: false,
      deleting: false,
      error: null,
      mockValue: {}
    }
  },
  computed: {
    ...mapGetters(['project']),
    sidebarWidth() {
      if (this.smAndDown) {
        return '100%'
      }
      if (this.$vuetify.breakpoint.lgAndDown) {
        return 400
      }
      return 480
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown
    },
    collection() {
      return this.$store.state.firebase.collections.find(x => x.id === this.$route.params.collectionId)
    }
  },
  watch: {
    collection: {
      handler(collection, oldCollection) {
        if (collection) {
          this.form = { fields: [], ...JSON.parse(JSON.stringify(collection)) }
          if (oldCollection?.fields && (collection.fields.length === (oldCollection.fields.length + 1))) {
            const oldFieldIds = oldCollection.fields.map(f => f.id)
            const newField = collection.fields.find(f => !oldFieldIds.includes(f.id))
            if (newField) {
              this.$router.push({ name: 'field', params: { fieldId: newField.id } })
              this.endAdd()
            }
          }
        }
      },
      immediate: true,
      deep: true
    },
    async 'form.fields.length'() {
      this.fieldsSaving = true
      const updateFields = {
        updatedAt: Timestamp.fromDate(new Date()),
        fields: this.form.fields
      }
      // if a preview field was removed, remove from preview
      for (const previewField of [
        'listPreviewTitle',
        'listPreviewSubtitle',
        'listPreviewAvatar'
      ]) {
        const fieldExists = this.form.fields.find(field => field.name === this.form[previewField])
        if (!fieldExists) {
          updateFields[previewField] = null
        }
      }
      await db.collection(`projects/${this.project.id}/collections`).doc(this.collection.id).update(updateFields)
      this.fieldsSaving = false
    }
  },
  methods: {
    startAdd() {
      this.addingNewField = true
      this.sidebar = true
    },
    addField(field) {
      this.form.fields.push(field)
      this.endAdd()
    },
    endAdd() {
      if (this.smAndDown) {
        this.sidebar = false
      }
      this.addingNewField = false
    },
    async save() {
      const form = this.$refs.collectionForm
      const formIsValid = await form.validate()
      if (!formIsValid) { return }
      const changedKeys = Object.keys(this.form).filter((key) => {
        return !['updatedAt', 'createdAt'].includes(key) && !isEqual(this.form[key], this.collection[key])
      })
      if (!changedKeys.length) {
        this.$store.dispatch('snackbar/new', { message: 'Collection Saved.' })
        this.$router.push({ name: 'collections' })
        return
      }
      const changedFields = {}
      changedKeys.forEach((key) => {
        changedFields[key] = this.form[key]
      })
      this.saving = true
      try {
        await db.collection(`projects/${this.project.id}/collections`).doc(this.collection.id).update({
          updatedAt: Timestamp.fromDate(new Date()),
          ...changedFields
        })
        this.$store.dispatch('snackbar/new', { message: 'Collection Saved.' })
        this.$router.push({ name: 'collections' })
      } catch (error) {
        this.error = error
      }
      this.saving = false
    },
    async deleteCollection() {
      if (confirm('Are you sure you want to delete this collection from fireful?')) {
        this.deleting = true
        await db.collection(`projects/${this.project.id}/collections`).doc(this.collection.id).delete()
        this.$store.dispatch('snackbar/new', { message: 'Collection Deleted.' })
        this.$router.push({ name: 'collections' })
        this.deleting = false
      }
    }
  }
}
</script>
